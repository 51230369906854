import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-scroll';
import { Tooltip } from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faDiscord, faLinkedin, faTwitter, faTwitch, faStackOverflow } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeftLong, faArrowRightLong, faChevronRight, faEnvelope, faLock, faPhoneVolume, faRotateRight } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const words = ['Hello', 'Bonjour', 'Hola', 'Ciao', 'Namaste', '< />'];
  let currentIndex = useRef(0);
  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(changeWord, 500);

    return () => clearInterval(interval.current);
  }, []);

  function changeWord() {
    const textElement = document.getElementById('text');
    if (textElement) {
      if (currentIndex.current < words.length) {
        textElement.textContent = words[currentIndex.current];
        currentIndex.current++;
      } else {
        clearInterval(interval.current);
        setIsLoading(false);
      }
    }
  }

  const navSlide = () => {
    const burger = document.querySelector('.burger');
    const nav = document.querySelector('.nav-bar');
    nav.classList.toggle('nav-active');
    burger.classList.toggle('toggle');
  }

  const go = () => {
    const nav = document.querySelector('.nav-bar');
    const burger = document.querySelector('.burger');
    nav.classList.remove('nav-active');
    burger.classList.remove('toggle');
  }


  return (
    <div>
      {isLoading ? (
        <div className='loader'>
          <p id="text" className="text-animation"></p>
        </div>

      ) : (
        <React.Fragment>
          <div id="main">
            <div className="main-text">
              <h1>code</h1>
              <h1>pro</h1>
            </div>
            <div id='nav'>
              <div className='navBar'>
                <div className='logo'>
                  <img src="./assets/Hp-logo-black.png" alt="logo" />
                </div>
                <div className='dark-light-mode flex'>
                  <nav >
                    <ul >
                      <Link to="about" spy={true} smooth={true} offset={0} duration={500}><li className='nav-item'>About</li></Link>
                      <Link to="skills" spy={true} smooth={true} offset={0} duration={700}><li className='nav-item'>Skills</li></Link>
                      <Link to="work" spy={true} smooth={true} offset={0} duration={900}><li className='nav-item'>Work</li></Link>
                      <Link to="contact" spy={true} smooth={true} offset={0} duration={1600}><li className='nav-item' id='contactSection'>Contact</li></Link>
                    </ul>

                  </nav>

                </div>
              </div>

              <nav id='menu-nav'>
                <div className="nav-bar-logo">
                  <div className='logo'>
                    <img src="./assets/Hp-logo-black.png" alt="logo" />
                  </div>
                  <div className="burger" onClick={navSlide}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                  </div>
                </div>

                <div className="nav-bar">
                  <Link to="about" onClick={go} ><li className='nav-childs' >About</li></Link>
                  <Link to="skills" onClick={go}><li className='nav-childs'>Skills</li></Link>
                  <Link to="work" onClick={go}><li className='nav-childs'>Work</li></Link>
                  <Link to="contact" onClick={go}><li className='nav-childs' id='contactSection'>Contact</li></Link>

                </div>

              </nav>
            </div>
            <div id='home'>
              <div className='home-container'>
                <div className='verticalBar'>
                  <a href="https://github.com/StealthAdder" className='Github' target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                  <Tooltip anchorSelect=".Github" className='tool' place="right">
                    GitHub
                  </Tooltip>
                  <a href="https://discord.com/channels/@me" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faDiscord} className='discord' />
                  </a>
                  <Tooltip anchorSelect=".discord" className='tool' place="right">
                    Discord
                  </Tooltip>
                  <a href="https://www.linkedin.com/in/hariharan-parthiban-15194a178/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className='LinkedIn' />
                  </a>
                  <Tooltip anchorSelect=".LinkedIn" className='tool' place="right">
                    LinkedIn
                  </Tooltip>
                  <a href="https://twitter.com/StealthAdder" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitter} className='twitter' />
                  </a>
                  <Tooltip anchorSelect=".twitter" className='tool' place="right">
                    Twitter
                  </Tooltip>
                  <a href="https://www.twitch.tv/zeo_noah" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitch} className='twitch' />
                  </a>
                  <Tooltip anchorSelect=".twitch" className='tool' place="right">
                    Twitch
                  </Tooltip>
                  <a href="mailto:hariharanp2435@gmail.com" className="Mail">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                  <Tooltip anchorSelect=".Mail" className='tool' place="right">
                    Mail
                  </Tooltip>
                  <a href="https://stackoverflow.com/users/12732818/hariharan-parthiban?tab=profile" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faStackOverflow} className='StackOverflow' />
                  </a>
                  <Tooltip anchorSelect=".StackOverflow" className='tool' place="right">
                    StackOverflow
                  </Tooltip>
                </div>
                <div className="home-content">
                  <div className="intro">
                    <h1 className="greet">Hi there</h1>
                    <img src="./assets/hand-wave.png" alt="Hand-Wave" />
                    <h1 className="greet mr-4">, </h1>
                    <h1 className="greet"> I'm</h1>
                  </div>
                  <div className='name'>
                    <h1>&#x3C; Hariharan Parthiban &#x2F;&#x3E;</h1>
                  </div>
                  <div className="home-position">
                    <div className='position-cont'>
                      <h1 className='position'>Backend Engineer</h1>
                      <img src="./assets/backendEngineer.png" alt="Backend-Engineer" />
                    </div>

                    <h1 className='position mx-5' id='plus'>+</h1>
                    <div className='position-cont'>
                      <h1 className='position'>Gamer</h1>
                      <img src="./assets/controller.png" alt="controller" />
                    </div>
                  </div>
                  <div className='intro-text'>
                    <p>I specialize in building and maintaining the behind-the-scenes magic that powers web applications.</p>
                  </div>


                  <div className='see-more-div'>
                    <a href="#about" className='see-more'>
                      <p>See more about me</p>
                      <img className='w-6 ml-3' src="./assets/arrow.gif" alt="arrow-right" />
                    </a>
                  </div>

                </div>
                <div className='home-img'>
                  <img src="./assets/rocket-guy.png" alt="rocket-guy" />
                </div>

              </div >
              <div className='social-connections'>
                <div className='socials'>
                  <a href="https://github.com/StealthAdder" className='Github' target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                  <Tooltip anchorSelect=".Github" className='tool' place="right">
                    GitHub
                  </Tooltip>
                  <a href="https://discord.com/channels/@me" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faDiscord} className='discord' />
                  </a>
                  <Tooltip anchorSelect=".discord" className='tool' place="right">
                    Discord
                  </Tooltip>
                  <a href="https://www.linkedin.com/in/hariharan-parthiban-15194a178/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className='LinkedIn' />
                  </a>
                  <Tooltip anchorSelect=".LinkedIn" className='tool' place="right">
                    LinkedIn
                  </Tooltip>
                  <a href="https://twitter.com/StealthAdder" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitter} className='twitter' />
                  </a>
                  <Tooltip anchorSelect=".twitter" className='tool' place="right">
                    Twitter
                  </Tooltip>
                  <a href="https://www.twitch.tv/zeo_noah" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitch} className='twitch' />
                  </a>
                  <Tooltip anchorSelect=".twitch" className='tool' place="right">
                    Twitch
                  </Tooltip>
                  <a href="mailto:hariharanp2435@gmail.com" className="Mail">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                  <Tooltip anchorSelect=".Mail" className='tool' place="right">
                    Mail
                  </Tooltip>
                  <a href="https://stackoverflow.com/users/12732818/hariharan-parthiban?tab=profile" className='StackOverflow' target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faStackOverflow} />
                  </a>
                  <Tooltip anchorSelect=".StackOverflow" className='tool' place="right">
                    StackOverflow
                  </Tooltip>
                </div>
              </div>
              <div className='home-img-responsive'>
                <img src="./assets/rocket-guy.png" alt="rocket-guy" />
              </div>
            </div>
          </div>
          <div id="about">
            <div className="about-container">
              <div className='about-img'>
                <img src="./assets/programmer1.png" alt="programmer" />
              </div>
              <div className='about-content'>
                <h1>About me</h1>
                <p>
                  I'm Hariharan Parthiban, a passionate Backend Engineer dedicated to continuous learning and innovation. With a strong track record in team management and project planning, I thrive in fostering collaborative, inclusive work environments. My problem-solving skills and commitment to quality drive me to deliver efficient solutions that align with business needs. I specialize in back-end web development, scalable architecture, and rigorous test-driven development practices. My relentless drive to explore emerging technologies keeps me at the forefront of the rapidly evolving tech landscape, ensuring I can always deliver cutting-edge solutions to complex challenges.</p>
              </div>
            </div>
            <div className="about-container-resp">
              <h1>About me</h1>
              <div className='aboutcontent'>
                <p>I'm Hariharan Parthiban, a passionate Backend Engineer dedicated to continuous learning and innovation. With a strong track record in team management and project planning, I thrive in fostering collaborative, inclusive work environments. My problem-solving skills and commitment to quality drive me to deliver efficient solutions that align with business needs. I specialize in back-end web development, scalable architecture, and rigorous test-driven development practices. My relentless drive to explore emerging technologies keeps me at the forefront of the rapidly evolving tech landscape, ensuring I can always deliver cutting-edge solutions to complex challenges.</p>
              </div>
              <div className='about-img-responsive'>
                <img src="./assets/programmer1.png" alt="programmer" />
              </div>
            </div>
          </div>
          <div id="skills">
            <h1>Skills</h1>
            <div className='skills-container'>
              <div className="skills-content">

                <p>I'm an experienced Backend Engineer (SDE-2) proficient in tools like Nginx, Docker, GitHub, and Jira. My expertise lies in Node.js, TypeScript, and scalable microservices architecture. I excel in AWS, crafting fault-tolerant applications, and optimize system performance using Redis and Linux. Eager to join dynamic teams, I drive tech advancements and deliver innovative solutions while fostering inclusive work environments and ensuring high-quality results. My dedication to continuous learning ensures I stay at the forefront of technology trends.</p>
                <div className='skill-icons'>
                  <div className='icons'>
                    <img id='typescript' src="./assets/Typescript.png" alt="typescript" />
                  </div>
                  <div className='icons'>
                    <img id='redis' src="./assets/Redis.png" alt="Redis" />
                  </div>
                  <div className='icons'>
                    <img id='docker' src="./assets/docker.png" alt="docker" />
                  </div>
                  <div className='icons'>
                    <img id='aws' src="./assets/AWS.png" alt="AWS" />
                  </div>
                  <div className='icons'>
                    <img id='linux' src="./assets/Linux.png" alt="linux" />
                  </div>
                  <div className='icons'>
                    <img id='go' src="./assets/GoLang.png" alt="Go-Language" />
                  </div>
                  <div className='icons'>
                    <img id='git' src="./assets/Git.png" alt="git" />
                  </div>
                  <div className='icons'>
                    <img id='GitHub' src="./assets/Github.png" alt="GitHub" />
                  </div>
                  <div className='icons'>
                    <img id='MongoDB' src="./assets/MongoDB.png" alt="MongoDB" />
                  </div>
                  <div className='icons'>
                    <img id='NodeJS' src="./assets/NodeJS.png" alt="NodeJS" />
                  </div>
                  <div className='icons'>
                    <img id='CI-CD' src="./assets/CI-CD.png" alt="CI-CD" />
                  </div>
                  <div className='icons'>
                    <img id='ReactJS' src="./assets/React.png" alt="ReactJS" />
                  </div>
                  <Tooltip anchorSelect="#docker" place="right">
                    Docker
                  </Tooltip>
                  <Tooltip anchorSelect="#git" place="right">
                    Git
                  </Tooltip>
                  <Tooltip anchorSelect="#typescript" place="right">
                    Typescript
                  </Tooltip>
                  <Tooltip anchorSelect="#ReactJS" place="right">
                    ReactJS
                  </Tooltip>
                  <Tooltip anchorSelect="#aws" place="right">
                    AWS
                  </Tooltip>
                  <Tooltip anchorSelect="#linux" place="right">
                    Linux
                  </Tooltip>
                  <Tooltip anchorSelect="#redis" place="right">
                    Redis
                  </Tooltip>
                  <Tooltip anchorSelect="#CI-CD" place="right">
                    CI/CD
                  </Tooltip>
                  <Tooltip anchorSelect="#GitHub" place="right">
                    GitHub
                  </Tooltip>
                  <Tooltip anchorSelect="#go" place="right">
                    Go
                  </Tooltip>
                  <Tooltip anchorSelect="#NodeJS" place="right">
                    NodeJS
                  </Tooltip>
                  <Tooltip anchorSelect="#MongoDB" place="right">
                    MongoDB
                  </Tooltip>
                </div>

              </div>
              <div className="skills-img">
                <img src="./assets/skill-section-img.png" alt="skills" />
              </div>
            </div>
            <div className='about-img-responsive'>
              <img src="./assets/skill-section-img.png" alt="skills" />
            </div>
          </div>
          <div id='work'>
            <h1 className='work-title'>Work Experience</h1>
            <div className='flex items-center justify-around work-container'>
              <div className="window-container">
                <div className='window-bar flex'>
                  <div className='color-container'>
                    <div className='red'></div>
                  </div>
                  <div className='color-container'>
                    <div className='yellow'></div>
                  </div>
                  <div className='color-container'>
                    <div className='green'></div>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className="url">
                    <div className='arrows flex items-center'>
                      <div className='l-arrow'>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                      </div>
                      <div className="r-arrow">
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </div>
                      <div className="arrow-rotate">
                        <FontAwesomeIcon icon={faRotateRight} />
                      </div>
                      <a href='https://ineuron.ai/' target="_blank" rel="noreferrer">
                        <div className='url-bar flex items-center'>
                          <div className='lock'>
                            <FontAwesomeIcon icon={faLock} />
                          </div>

                          <p>ineuron.ai</p>

                        </div>
                      </a>
                    </div>
                  </div>
                  <a href='mailto:hariharanp2435@gmail.com' className='url-avatar'>
                    <img src="./assets/avatar.png" alt="avatar" />
                  </a>
                </div>
                <div className='window-screen p-10'>
                  <h1 className='company uppercase tracking-widest'>
                    <a href='https://ineuron.ai/' target="_blank" rel="noreferrer">
                      iNeuron.ai / Physics Wallah
                    </a>
                  </h1>
                  <h3 className='opacity-60 pt-3 pb-2 position-work'>Backend Engineer [02/2022 - Present]</h3>
                  <div className='flex items-center pt-5'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>Constantly Improving the engineering process. </p>
                  </div>
                  <div className='flex items-center pt-3'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>DevOps (AWS).</p>
                  </div>
                  <div className='flex pt-3'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>Neurolab - A lightening-fast virtual coding envirnoment, application IDE loading time less than ~5 seconds also
                      retrives backups.</p>
                  </div>
                  <div className='flex items-center pt-3'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>Engineering Lead for a team of 5+ frontend, backend
                      engineers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="window-container">
                <div className='window-bar flex'>
                  <div className='color-container'>
                    <div className='red'></div>
                  </div>
                  <div className='color-container'>
                    <div className='yellow'></div>
                  </div>
                  <div className='color-container'>
                    <div className='green'></div>
                  </div>
                </div>
                <div className='flex items-center'>
                  <div className="url">
                    <div className='arrows flex items-center'>
                      <div className='l-arrow'>
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                      </div>
                      <div className="r-arrow">
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </div>
                      <div className="arrow-rotate">
                        <FontAwesomeIcon icon={faRotateRight} />
                      </div>
                      <a href='https://www.workplay.digital/' target="_blank" rel="noreferrer">
                        <div className='url-bar flex items-center'>
                          <div className='lock'>
                            <FontAwesomeIcon icon={faLock} />
                          </div>
                          <p>workplay.digital</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <a href='mailto:hariharanp2435@gmail.com' className='url-avatar'>
                    <img src="./assets/avatar.png" alt="avatar" />
                  </a>
                </div>
                <div className='window-screen p-10'>
                  <h1 className='uppercase tracking-widest company'>
                    <a href='https://www.workplay.digital/' target="_blank" rel="noreferrer">
                      Workplay studios Pvt Ltd
                    </a>
                  </h1>
                  <h3 className='opacity-60 pt-3 pb-2 position-work'>Trainee/Intern [06/2021 - 01/2022]</h3>
                  <div className='flex items-center pt-5'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>Built Multiplayer Games running on Socket.IO.</p>
                  </div>
                  <div className='flex items-center pt-3'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>Built API's for handling & storing data feeds to DB.</p>
                  </div>
                  <div className='flex items-center pt-3'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>Deployment of Web Builds onto Server & Managing
                      them.</p>
                  </div>
                  <div className='flex items-center pt-3'>
                    <div className='chevron'>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <p className='pl-3 bullet-points'>Maintaining Site & Server via panels.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex items-center pt-10 justify-center scroll-text'>
              <p className='text-center scroll-projects mr-3'>Scroll down to view projects</p>
              <img className='h-6' src="./assets/dot-arrow.png" alt="arrow-down" />
            </div>
          </div>
          <div id="projects">
            <div className="project-container">
              <div className='project-info'>
                <h1 className='mb-5'>PW SKILLS</h1>
                <p className=' tracking-wider text-gray-500'>
                  PW Skills (by Physicswallah) stands as an exceptional Learning Management System (LMS) that boasts an extensive user base of over 1 million actively engaged learners. This cutting-edge platform not only facilitates seamless integration but also offers an in-house built affiliate portal, enabling effortless coupon management and automated payout generation. Moreover, it provides an integrated job portal, empowering users to effortlessly explore and apply for a wide range of relevant and enticing job opportunities.</p>
              </div>
              <div class="video-container">
                <a href="https://pwskills.com/" target='_blank' rel="noreferrer">
                  <img src="./assets/pwskills-recording.gif" alt="pw-skills" />
                </a>
              </div>

            </div>
            <div class="video-container-resp">
              <a href="https://pwskills.com/" target='_blank' rel="noreferrer">
                <img src="./assets/pwskills-recording.gif" alt="pw-skills" />
              </a>
            </div>
            <div className="project-container mt-14" id='project-cont'>

              <div class="video-container" id='neurobuddy'>
                <a href="https://neurolab.ineuron.ai/" target='_blank' rel="noreferrer">
                  <img src="./assets/neurolab.gif" alt="neurobuddy" />
                </a>
              </div>
              <div className='project-info'>
                <h1 className='mb-5'>NEUROLAB</h1>
                <p className=' tracking-wider text-gray-500'>
                  Developed a cutting-edge, user-empowering solution that enables coding anytime and anywhere, eliminating the requirement for specialized software or hardware. Leveraging Docker and AWS, our in-house platform revolutionized the coding experience while dramatically slashing costs associated with third-party coding platforms. In fact, our cost savings surpassed expectations, achieving an outstanding 200% reduction compared to previous solutions. Furthermore, our remarkable launch time of under 5 seconds sets a new industry standard, delivering unparalleled efficiency and productivity to our users.</p>
              </div>

            </div>
            <div class="video-container-resp">
              <a href="https://neurolab.ineuron.ai/" target='_blank' rel="noreferrer">
                <img src="./assets/neurolab.gif" alt="neurobuddy" />
              </a>
            </div>
          </div>
          <div id="contact">
            <h1 className='contact-title'>Contact me</h1>
            <div className="contact-container">
              <div className='content-contact'>
                <div>
                  <div className='flex items-center contact-content'>
                    <p>Interested in working together? We should queue up a time to chat.</p>
                    <a href="mailto:hariharanp2435@gmail.com" className='contactBtn'>
                      <div className='flex items-center contact-btn ml-6'>
                        Get in touch
                        <div className='ml-2'><img className='w-5' src="./assets/paper-plane.gif" alt="paper-plane" /></div>
                      </div>
                    </a>
                  </div>
                  <div className='contact-icons'>
                    <div className='flex pt-5 contact-mail'>
                      <div className='flex items-center'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <p className='ml-3 mr-2'>Email :</p>
                      </div>
                      <a href="mailto:hariharanp2435@gmail.com">
                        hariharanp2435@gmail.com
                      </a>
                    </div>
                    <div className='flex pt-5 contact-phone'>
                      <div className='flex items-center'>
                        <FontAwesomeIcon icon={faPhoneVolume} />
                        <p className='ml-3 mr-2'>Phone :</p>
                      </div>
                      <h6>
                        +91 8296852039
                      </h6>

                    </div>
                    <div className='flex items-center pt-10 pb-6  reach '>
                      <p className='mr-4'>Reach me out on</p>
                      <img src="./assets/curlyarrowdown.gif" alt="arrow-down" />
                    </div>

                    <div className='flex socials-contact'>
                      <a className='linkedIn-socials' href="https://www.linkedin.com/in/hariharan-parthiban-15194a178/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                      <Tooltip anchorSelect=".linkedIn-socials" place="bottom">
                        LinkedIn
                      </Tooltip>
                      <a className='mail-socials' href="mailto:hariharanp2435@gmail.com">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </a>
                      <Tooltip anchorSelect=".mail-socials" place="bottom">
                        Mail
                      </Tooltip>
                      <a className='github-socials' href="https://github.com/StealthAdder" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faGithub} />
                      </a>
                      <Tooltip anchorSelect=".github-socials" place="bottom">
                        GitHub
                      </Tooltip>
                      <a className='stackOverflow-socials' href="https://stackoverflow.com/users/12732818/hariharan-parthiban?tab=profile" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faStackOverflow} />
                      </a>
                      <Tooltip anchorSelect=".stackOverflow-socials" place="bottom">
                        stackoverflow
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className='contact-img'>
                <img src="./assets/mailbox-bird.png" alt="bird-mailbox" />
              </div>
            </div >
          </div >
        </React.Fragment>
      )}
    </div >
  );
}

export default App;
